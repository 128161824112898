import React, { useState, useEffect, useCallback, useMemo } from "react";
import SelectSearch from "./SelectSearch";
import SelectBeforeInput from "./SelectBeforeInput";
import { Button, Form, Select, InputNumber, Flex, List, Statistic } from "antd";
import IntlCurrencyInput from "react-intl-currency-input";
import pattern from "../api/pattern";
const { localeTable } = pattern;

const FormBudget = (props) => {
    const [selectedTreatments, setSelectedTreatments] = useState([]);
    const [finalValue, setFinalValue] = useState(0);

    const [selectSearchValue, setSelectSearchValue] = useState();
    const [treatmentsSumValue, setTreatmentsSumValue] = useState(0);

    const initialValues = {
        treatments_ids: props.originalTreatments?.map((t) => t.id) || [],
        discount: props.discountValue || { value: 0, type: "%" },
    };

    const getTreatmentsSumValue = (changedValue, allValues) => {
        const treatmentsIds = allValues["treatments_ids"] || [];

        const treatments = props.selectSearchProps.data.treatments.filter(
            (treatment) => treatmentsIds.includes(treatment.id)
        );

        setSelectedTreatments(treatments);
    };

    const handleCurrencyInput = (event, value, maskedValue) => {
        props.form.setFieldsValue({
            ["final_value"]: value,
        });
    };

    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };

    useEffect(() => {
        if (props.openDrawer) {
            setSelectedTreatments(props.originalTreatments || []);
        } else {
            setSelectedTreatments([]);
            props.form.resetFields(["discount", "treatments_ids"]);
        }
    }, [props.openDrawer, props.originalTreatments]);

    useEffect(() => {
        const sumTreatmentsValue = selectedTreatments.reduce((sum, item) => {
            return sum + Number(item.original_value || item.value || 0);
        }, 0);

        setTreatmentsSumValue(sumTreatmentsValue);

        const discount = props.form.getFieldValue("discount") || {};
        const discountValue = discount.value || 0;
        const typeDiscount = discount.type || "%";

        let calculatedFinalValue = sumTreatmentsValue;
        if (typeDiscount === "R$") {
            calculatedFinalValue = sumTreatmentsValue - discountValue;
        }
        if (typeDiscount === "%") {
            calculatedFinalValue =
                sumTreatmentsValue - (discountValue / 100) * sumTreatmentsValue;
        }
        setFinalValue(calculatedFinalValue);
        props.form.setFieldsValue({ ["final_value"]: calculatedFinalValue });
    }, [selectedTreatments, props.form]);

    const discountValue = useMemo(
        () => props.form.getFieldValue("discount") || {},
        [props.form]
    );

    const handleDiscountChange = useCallback(
        (value) => {
            props.form.setFieldsValue({ ["discount"]: value });
        },
        [props.form]
    );

    const handleRenderFormItens = (form) => {
        switch (form.formType) {
            case "searchInput":
                return (
                    <SelectSearch
                        placeholder={form.formPlaceholder}
                        mode={form.formSelectMode}
                        value={selectSearchValue}
                        setValue={setSelectSearchValue}
                        initialData={
                            props.selectSearchProps.data[form.formSelectEntity]
                        }
                        endpoint={props.selectSearchProps.endpoint[
                            form.formSelectEntity
                        ].replace("${}", [
                            props.form.getFieldValue(form.formSelectMain),
                        ])}
                        onChange={(value) => {
                            props.form.setFieldsValue({
                                [form.formItemName]: value,
                            });
                        }}
                    />
                );
            case "selectBeforeInput":
                return (
                    <SelectBeforeInput
                        value={discountValue}
                        onChange={handleDiscountChange}
                        treatmentsSumValue={treatmentsSumValue}
                    />
                );
            case "inputCurrency":
                return (
                    <IntlCurrencyInput
                        id="currencyInput"
                        currency="BRL"
                        max={10000000}
                        onChange={handleCurrencyInput}
                        config={currencyConfig}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Form
            name={props.name}
            onFinish={props.onFinish}
            onFinishFailed={props.onFinishFailed}
            autoComplete="off"
            form={props.form}
            layout="vertical"
            onValuesChange={getTreatmentsSumValue}
            initialValues={initialValues} // Definimos os valores iniciais aqui
        >
            {props.formItens.map((form) => (
                <React.Fragment key={form.formItemName}>
                    <Form.Item
                        name={form.formItemName}
                        label={form.formItemLabel}
                        rules={form.formItemRules}
                        // Remova o formItemInitialValue daqui
                    >
                        {handleRenderFormItens(form)}
                    </Form.Item>
                </React.Fragment>
            ))}
            <List
                size="small"
                bordered
                locale={localeTable}
                dataSource={selectedTreatments}
                renderItem={(item) => (
                    <List.Item>
                        {item.name} -{" "}
                        {`R$ ${Number(
                            item.original_value || item.value || 0
                        ).toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                        })}`}
                    </List.Item>
                )}
            />
            <br />
            <Statistic
                title="Valor final:"
                value={`R$ ${Number(finalValue).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                })}`}
            />
            <br />
            <Form.Item>
                <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                >
                    {props.buttonText}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default FormBudget;
