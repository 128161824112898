import React, { useEffect, useRef } from "react";
import { usePDFContext } from "../../context/PDFProvider";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfMake from "html-to-pdfmake";
import dayjs from "dayjs";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const EvolutionPdf = (props) => {
    const { setPdfBase64 } = usePDFContext();

    const iframeRef = useRef();

    const isHtml = (str) => {
        const doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(
            (node) => node.nodeType === 1
        );
    };

    const getDescriptionContent = (description) => {
        if (isHtml(description)) {
            return htmlToPdfMake(description);
        } else {
            return htmlToPdfMake(`<p>${description}</p>`);
        }
    };

    useEffect(() => {
        // Verifica se as props necessárias estão disponíveis
        if (!props.evolution || !props.patient || !props.account) {
            return;
        }

        const tableBody = [
            [
                {
                    columns: [
                        {
                            width: "auto",
                            text: "Nome: ",
                            bold: true,
                        },
                        props.patient.name,
                    ],
                    columnGap: 10,
                },
                {
                    columns: [
                        {
                            width: "auto",
                            text: "Data de nascimento: ",
                            bold: true,
                        },
                        props.patient.birthFormatted,
                    ],
                    columnGap: 10,
                },
            ],
            [
                {
                    columns: [
                        {
                            width: "auto",
                            text: "CPF: ",
                            bold: true,
                        },
                        props.patient.cpf,
                    ],
                    columnGap: 10,
                },
                {
                    columns: [
                        {
                            width: "auto",
                            text: "Telefone: ",
                            bold: true,
                        },
                        props.patient.phone,
                    ],
                    columnGap: 10,
                },
            ],
        ];

        // Condicionalmente adicionar o responsible_cpf
        if (props.patient.responsible_cpf) {
            tableBody.push([
                {
                    columns: [
                        {
                            width: "auto",
                            text: "CPF do Responsável: ",
                            bold: true,
                        },
                        props.patient.responsible_cpf,
                    ],
                    columnGap: 10,
                },
                {},
            ]);
        }

        const documentDefinition = {
            header: function () {
                return {
                    columns: [
                        {
                            text: props.account.name,
                            alignment: "left",
                            margin: [0, 10, 40, 0],
                        },
                        {
                            text: dayjs().format("DD/MM/YYYY"),
                            alignment: "right",
                            margin: [0, 10, 40, 0],
                        },
                    ],
                    margin: [40, 10, 0, 0],
                };
            },
            content: [
                "\n\n",
                {
                    text: "Evolução do paciente",
                    style: "header",
                },

                "\n\n",

                {
                    table: {
                        widths: ["*", "*"],
                        body: tableBody, // Use o tableBody aqui
                    },
                    layout: "noBorders",
                },

                "\n\n",

                {
                    canvas: [
                        {
                            type: "line",
                            x1: 0,
                            y1: 5,
                            x2: 595 - 2 * 40,
                            y2: 5,
                            lineWidth: 1,
                        },
                    ],
                },
                "\n\n",
                {
                    table: {
                        widths: ["*", "*"],

                        body: [
                            [
                                {
                                    columns: [
                                        {
                                            width: "auto",
                                            text: "Data de realização: ",
                                            bold: true,
                                        },
                                        props.evolution.dateFormatted,
                                    ],
                                    columnGap: 10,
                                },
                                {
                                    columns: [
                                        {
                                            width: "auto",
                                            text: "Dentista: ",
                                            bold: true,
                                        },
                                        {
                                            text: props.evolution.dentistName,
                                            noWrap: true,
                                        },
                                    ],
                                    columnGap: 10,
                                },
                            ],
                        ],
                    },
                    layout: "noBorders",
                },
                "\n",
                {
                    table: {
                        widths: ["*"],

                        body: [[{ text: "Descrição:", bold: true }]],
                    },
                    layout: "noBorders",
                },
                {
                    table: {
                        widths: ["*"],

                        body: [
                            [
                                {
                                    stack: getDescriptionContent(
                                        props.evolution.description
                                    ),
                                },
                            ],
                        ],
                    },
                    layout: "noBorders",
                },
                "\n",
                props.evolution.patient_treatment_id
                    ? {
                          table: {
                              widths: ["*"],

                              body: [
                                  [
                                      {
                                          columns: [
                                              {
                                                  width: "auto",
                                                  text: "Tratamento: ",
                                                  bold: true,
                                              },
                                              props.evolution.treatment_name +
                                                  "\n\n\n",
                                          ],
                                          columnGap: 10,
                                      },
                                  ],
                              ],
                          },
                          layout: "noBorders",
                      }
                    : "",

                {
                    canvas: [
                        {
                            type: "line",
                            x1: 0,
                            y1: 5,
                            x2: 595 - 2 * 40,
                            y2: 5,
                            lineWidth: 1,
                        },
                    ],
                },
                "\n\n\n\n",
                {
                    text: "Assino este declarando verdadeiras as informações ditas acima",
                    alignment: "center",
                    margin: [0, 5, 0, 0],
                },
                "\n\n",
                {
                    text: "________________________________________________________",
                    alignment: "center",
                    margin: [0, 5, 0, 0],
                },
                "\n",
                {
                    text: `Assinatura do Paciente: ${props.patient.name}`,
                    alignment: "center",
                    fontSize: 10,
                    margin: [0, 5, 0, 0],
                },
            ],
            footer: function (currentPage, pageCount) {
                return {
                    margin: 10,
                    columns: [
                        {
                            text: [
                                {
                                    text:
                                        "Página " +
                                        currentPage.toString() +
                                        " de " +
                                        pageCount,
                                },
                            ],
                            alignment: "left",
                            fontSize: 9,
                            margin: [40, 0, 0, 0],
                        },
                    ],
                };
            },

            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                    margin: [0, 0, 0, 10],
                },
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

        pdfDocGenerator.getBase64((base64) => {
            setPdfBase64(base64);
            const base64String = "data:application/pdf;base64," + base64;

            iframeRef.current.src = props.sign
                ? `${base64String}#toolbar=0`
                : base64String;
        });
    }, [props.evolution, props.patient, props.account, props.sign]);

    return (
        <iframe
            ref={iframeRef}
            style={{ width: "100%", height: "500px", border: "none" }}
        ></iframe>
    );
};

export default EvolutionPdf;
