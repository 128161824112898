import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import Layout from "../layout/Layout";
import { Table, Input } from "antd";
import axios from "../api/axios";
import pattern from "../api/pattern";

const { endpoints, localeTable } = pattern;
const { Search } = Input;

const Logs = () => {
    const { isMobile } = useAuth();
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.logs.index);
            setData(response.data);
            setOriginalData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onSearch = (value) => {
        const dataSearch = originalData.filter((logs) => {
            return (
                logs.entity.toLowerCase().includes(value.toLowerCase()) ||
                logs.action.toLowerCase().includes(value.toLowerCase()) ||
                logs.user_email.toLowerCase().includes(value.toLowerCase())
            );
        });

        setData(dataSearch);
    };

    const columns = [
        {
            title: "Entidade",
            dataIndex: "entity",
            key: "entity",
        },
        {
            title: "Ação",
            dataIndex: "action",
            key: "action",
        },
        {
            title: "Usuário",
            dataIndex: "user_email",
            key: "user_email",
        },
        {
            title: "Data",
            dataIndex: "created_at_formatted",
            key: "created_at_formatted",
        },
    ];

    const expandedRowRender = (record) => {
        const { old_values, new_values } = record;
        const fields = Object.keys(new_values || {});

        if (fields.length === 0) {
            return <p>Nenhuma alteração registrada.</p>;
        }

        return (
            <Table
                dataSource={fields.map((field) => ({
                    key: field,
                    field,
                    oldValue: old_values ? old_values[field] : null,
                    newValue: new_values[field],
                }))}
                columns={[
                    {
                        title: "Campo",
                        dataIndex: "field",
                        key: "field",
                    },
                    {
                        title: "Valor Antigo",
                        dataIndex: "oldValue",
                        key: "oldValue",
                        render: (value, record) => (
                            <span
                                style={{
                                    color:
                                        value !== record.newValue
                                            ? "red"
                                            : "inherit",
                                }}
                            >
                                {value !== null ? value.toString() : "-"}
                            </span>
                        ),
                    },
                    {
                        title: "Novo Valor",
                        dataIndex: "newValue",
                        key: "newValue",
                        render: (value, record) => (
                            <span
                                style={{
                                    color:
                                        value !== record.oldValue
                                            ? "green"
                                            : "inherit",
                                }}
                            >
                                {value !== null ? value.toString() : "-"}
                            </span>
                        ),
                    },
                ]}
                pagination={false}
                rowKey="field"
                size="small"
            />
        );
    };

    const content = (
        <>
            <div
                className="table-header-button-search"
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "24px",
                }}
            >
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                    }}
                />
            </div>
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                rowKey={(record) => record.id}
                expandable={{
                    expandedRowRender,
                    rowExpandable: (record) =>
                        record.old_values &&
                        Object.keys(record.old_values).length > 0,
                }}
            />
        </>
    );
    return <Layout tabkey="60" openkey="5" content={content} />;
};

export default Logs;
