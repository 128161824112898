//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

//Library components
import { Button, Input, Form, Tabs, Flex, message, Layout, Avatar } from "antd";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";
const { Sider, Content } = Layout;

//Variables
const { endpoints } = pattern;

const Auth = () => {
    const { isAuthenticated, checkAuth } = useAuth();
    //tabs
    const [activeKey, setActiveKey] = useState("1");
    //forms
    const [formLogin] = Form.useForm();
    const [formRegister] = Form.useForm();

    const navigate = useNavigate();

    const handleUserLogged = () => {
        if (isAuthenticated) {
            navigate("/calendar");
        }
    };

    useEffect(() => {
        handleUserLogged();
    }, [isAuthenticated]);

    const handleTabs = (key) => {
        if (key === activeKey) {
            return;
        }

        formLogin.resetFields();
        formRegister.resetFields();
        setActiveKey(key);
    };

    const onFinishLogin = async (values) => {
        try {
            const response = await axios.post(endpoints.auth.login, values, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            });

            const accessToken = response.data.token;
            localStorage.setItem("token", accessToken);

            checkAuth();
        } catch (error) {
            message.error(error.response.data.message);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const items = [
        {
            key: "1",
            label: "Login",
            children: (
                <Form
                    name="login"
                    onFinish={onFinishLogin}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={formLogin}
                >
                    <Form.Item
                        name="identifier"
                        rules={[
                            {
                                required: true,
                                message: "Insira o nome de usuário ou email!",
                            },
                        ]}
                    >
                        <Input placeholder="Nome de usuário ou email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Insira a senha!",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Senha" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            style={{ width: "100%" }}
                            type="primary"
                            htmlType="submit"
                        >
                            Acessar
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
    ];

    return (
        <Layout style={{ height: "100vh" }}>
            <Content
                id="leftSideAuth"
                style={{
                    backgroundImage:
                        "url(https://i.pinimg.com/originals/b5/7f/00/b57f008443577f62396e67d5f1ec4c7d.jpg)",
                    backgroundSize: "cover",
                }}
            ></Content>
            <Sider
                id="rightSideAuth"
                width="50%"
                style={{ backgroundColor: "#FFF" }}
            >
                <Flex
                    align="center"
                    justify="center"
                    vertical
                    style={{ height: "100%" }}
                >
                    <img width="100" src="/logo.png" alt="Minha Imagem" />

                    <Tabs
                        style={{ width: "100%", padding: "20px" }}
                        defaultActiveKey="1"
                        activeKey={activeKey}
                        tabBarStyle={{ backgrounColor: "red" }}
                        items={items}
                        onChange={handleTabs}
                        centered
                    />
                </Flex>
            </Sider>
        </Layout>
    );
};

export default Auth;
