import dayjs from "dayjs";

const formattedDate = (values, role, userId) => {
    const date = dayjs(values.date).format("YYYY-MM-DD");
    const startTime = `${dayjs(values.timerange[0]).format("HH:mm")}:00`;
    const endTime = `${dayjs(values.timerange[1]).format("HH:mm")}:00`;

    return {
        user_id: role === "DENTIST" ? userId : values.user_id,
        patient_id: values.patient_id,
        dh_start: `${date} ${startTime}`,
        dh_end: `${date} ${endTime}`,
        activity: values.activity,
        is_anticipated: values.is_anticipated,
        categories_ids: values.categories_ids,
        status: values.status,
        note: values.note !== "<p><br></p>" ? values.note : null,
    };
};

export default formattedDate;
