//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import FormComponent from "../components/FormComponent";
import { SearchComponent } from "../components/SearchComponent";

import PatientHistoric from "../components/modals/PatientHistoric";
import PatientAnamnese from "../components/modals/PatientAnamnese";
import PatientTreatment from "../components/modals/PatientTreatment";
import PatientBudget from "../components/modals/PatientBudget";
import PatientEvolution from "../components/modals/PatientEvolution";

//Icons
import {
    FaEdit,
    FaHistory,
    FaClipboardList,
    FaEllipsisH,
    FaFileInvoiceDollar,
    FaTeethOpen,
    FaFolderOpen,
} from "react-icons/fa";
import { FaArrowUpRightDots } from "react-icons/fa6";

//Library components
import {
    Button,
    Descriptions,
    Table,
    Tag,
    Tooltip,
    message,
    Drawer,
    Form,
    Dropdown,
} from "antd";

//Settings
import dayjs from "dayjs";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";
import TextEditor from "../components/TextEditor";
import PatientFiles from "../components/modals/PatientFiles";

//Variables
const { endpoints, forms, localeTable } = pattern;

const Patients = () => {
    const { account, userName, isMobile, role, userId } = useAuth();
    const { getColumnSearchProps } = SearchComponent();

    const [id, setId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingButtonEdit, setLoadingButtonEdit] = useState(false);
    const [data, setData] = useState([]);
    const [dataUpdateConvenios, setDataUpdateConvenios] = useState([]);
    const [open, setOpen] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();

    const [isModalHistoricOpen, setIsModalHistoricOpen] = useState(false);
    const [isModalAnamneseOpen, setIsModalAnamneseOpen] = useState(false);
    const [isModalTreatmentsOpen, setIsModalTreatmentsOpen] = useState(false);
    const [isModalBudgetsOpen, setIsModalBudgetsOpen] = useState(false);
    const [isModalEvolutionsOpen, setIsModalEvolutionsOpen] = useState(false);
    const [isModalFilesOpen, setIsModalFilesOpen] = useState(false);
    const [loadingDocSignDownload, setLoadingDocSignDownload] = useState({});
    const [dataActiveTreatments, setDataActiveTreatments] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.patients.index);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchDataUpdateConvenios = async (id) => {
        try {
            const response = await axios.get(
                endpoints.patients.indexUpdateConvenios.replace("${}", id)
            );
            setDataUpdateConvenios(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataActiveTreatments = async () => {
        try {
            const response = await axios.get(endpoints.treatments.indexCreate);
            setDataActiveTreatments(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const handleDownloadDocumentSigned = async (filename, id) => {
        try {
            const response = await axios.post(
                endpoints.pdf.downloadPdf,
                {
                    filename,
                },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            const {
                data: { url },
            } = response;

            const responseBlob = await fetch(url);
            const blob = await responseBlob.blob();

            // Criando uma URL local para o Blob
            const localUrl = window.URL.createObjectURL(blob);

            // Criando e acionando o link para download
            const link = document.createElement("a");
            link.href = localUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            setLoadingDocSignDownload((prev) => ({ ...prev, [id]: false }));

            document.body.removeChild(link);
            // Liberar a URL local após o download
            window.URL.revokeObjectURL(localUrl);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const handleGetPatient = (id) => {
        const patient = { ...data.find((patient) => patient.id === id) };

        patient.birth = dayjs(patient.birth);

        return patient;
    };

    const showDrawer = async (id) => {
        const patient = handleGetPatient(id);
        form.setFieldsValue(patient);
        if (patient.photo_key) {
            await axios
                .get(endpoints.patients.getAvatarUrl.replace("${}", id))
                .then((res) => {
                    setFileList([
                        {
                            uid: "-1",
                            name: "photo_file",
                            status: "done",
                            url: res.data.url,
                        },
                    ]);
                });
        } else {
            setFileList([]);
        }
        fetchDataUpdateConvenios(id);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleUploadImagePatient = async (file) => {
        const formData = new FormData();
        formData.append("photo", file);

        try {
            const response = await axios.post(
                endpoints.patients.uploadImage,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return response.data.key;
        } catch (error) {
            console.error("Erro ao fazer upload da imagem:", error);
            message.error("Erro ao fazer upload da imagem.");
        }
    };

    const onFinish = async (values) => {
        setLoadingButtonEdit(true);
        values.note = values.note !== "<p><br></p>" ? values.note : null;
        values.responsible_cpf =
            values.responsible_cpf !== "___.___.___-__"
                ? values.responsible_cpf
                : null;
        try {
            if (fileList.length > 0 && fileList[0].originFileObj) {
                const file = fileList[0].originFileObj;
                const imageKey = await handleUploadImagePatient(file);
                values.photo_key = imageKey; // Adiciona a URL da imagem nos valores do formulário
            }

            await axios.post(
                endpoints.patients.update.replace("${}", id),
                values,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchData();
            setLoadingButtonEdit(false);
            onClose();
            message.success("Paciente atualizado com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const subitemsPatient = (id) => [
        {
            key: "1",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaHistory />}
                    onClick={() => {
                        setId(id);
                        setIsModalHistoricOpen(true);
                    }}
                >
                    Histórico
                </Button>
            ),
        },
        {
            key: "2",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaClipboardList />}
                    onClick={() => {
                        setId(id);
                        setIsModalAnamneseOpen(true);
                    }}
                >
                    Anamnese
                </Button>
            ),
        },
        {
            key: "6",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaArrowUpRightDots />}
                    onClick={() => {
                        setId(id);
                        setIsModalEvolutionsOpen(true);
                    }}
                >
                    Evoluções
                </Button>
            ),
        },
        {
            key: "3",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaTeethOpen />}
                    onClick={() => {
                        setId(id);
                        setIsModalTreatmentsOpen(true);
                    }}
                >
                    Tratamentos
                </Button>
            ),
        },
        {
            key: "4",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaFileInvoiceDollar />}
                    onClick={() => {
                        setId(id);
                        setIsModalBudgetsOpen(true);
                    }}
                >
                    Orçamento
                </Button>
            ),
        },
        {
            key: "5",
            label: (
                <Button
                    type="link"
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    icon={<FaEdit />}
                    onClick={() => {
                        setId(id);
                        showDrawer(id);
                    }}
                >
                    Editar
                </Button>
            ),
        },
    ];

    const columns = !isMobile
        ? [
              {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                  ...getColumnSearchProps("name"),
              },

              {
                  title: "Email",
                  dataIndex: "email",
                  key: "email",
                  ...getColumnSearchProps("email"),
              },
              {
                  title: "Convênio",
                  dataIndex: "convenio",
                  key: "convenio",
                  width: "20%",
                  filters: data
                      .flatMap((record) => record.convenios) // Junta todos os convênios dos pacientes
                      .map(({ name }) => ({
                          text: name,
                          value: name,
                      }))
                      .filter(
                          (v, i, a) =>
                              a.findIndex((t) => t.value === v.value) === i
                      ),
                  onFilter: (value, record) =>
                      record.convenios.some(
                          (convenio) => convenio.name === value
                      ),
                  render: (_, { convenios }) => (
                      <>
                          {convenios.map(({ id, name, color }) => {
                              return (
                                  <Tag key={id} color={color}>
                                      {name}
                                  </Tag>
                              );
                          })}
                      </>
                  ),
              },
              {
                  title: "Idade",
                  dataIndex: "age",
                  key: "age",
                  ...getColumnSearchProps("age"),
              },
              {
                  title: "Endereço",
                  dataIndex: "address",
                  key: "address",
                  ...getColumnSearchProps("address"),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  width: "200px",
                  render: (_, { id }) => (
                      <div
                          style={{
                              display: "grid",
                              gridTemplateColumns: "1fr 1fr 1fr",
                              rowGap: "10px",
                          }}
                      >
                          {/* <Tooltip title="Histórico">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      setIsModalHistoricOpen(true);
                                  }}
                              >
                                  <FaHistory />
                              </Button>
                          </Tooltip> */}
                          <Tooltip title="Anamnese">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      setIsModalAnamneseOpen(true);
                                  }}
                              >
                                  <FaClipboardList />
                              </Button>
                          </Tooltip>
                          <Tooltip title="Evoluções">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      setIsModalEvolutionsOpen(true);
                                  }}
                              >
                                  <FaArrowUpRightDots />
                              </Button>
                          </Tooltip>

                          <Tooltip title="Tratamentos">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      setIsModalTreatmentsOpen(true);
                                  }}
                              >
                                  <FaTeethOpen />
                              </Button>
                          </Tooltip>

                          <Tooltip title="Orçamento">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      setIsModalBudgetsOpen(true);
                                  }}
                              >
                                  <FaFileInvoiceDollar />
                              </Button>
                          </Tooltip>

                          <Tooltip title="Arquivos">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      setIsModalFilesOpen(true);
                                  }}
                              >
                                  <FaFolderOpen />
                              </Button>
                          </Tooltip>

                          <Tooltip title="Editar">
                              <Button
                                  style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      marginRight: "10px",
                                  }}
                                  onClick={() => {
                                      setId(id);
                                      showDrawer(id);
                                  }}
                              >
                                  <FaEdit />
                              </Button>
                          </Tooltip>
                      </div>
                  ),
              },
          ]
        : [
              {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Dropdown
                          menu={{ items: subitemsPatient(id) }}
                          placement="bottom"
                          arrow
                      >
                          <Button icon={<FaEllipsisH />} />
                      </Dropdown>
                  ),
              },
          ];

    const expandedRowRender = (record) => {
        const items = !isMobile
            ? [
                  {
                      label: "CPF",
                      children: record.cpf,
                  },
                  ...(record.responsible_cpf
                      ? [
                            {
                                label: "CPF do responsável",
                                children: record.responsible_cpf,
                            },
                        ]
                      : []),
                  {
                      label: "Telefone",
                      children: record.phone,
                  },
                  {
                      label: "Data de nascimento",
                      children: record.birthFormatted,
                  },
                  {
                      label: "Profissão",
                      children: record.occupation,
                  },
                  {
                      label: "Sexo",
                      children: record.gender,
                  },
                  {
                      label: "Notas",
                      children:
                          record.note === null ? (
                              "-"
                          ) : (
                              <div
                                  style={{ maxWidth: "300px" }}
                                  className="textEditorDescription"
                              >
                                  <TextEditor
                                      readOnly={true}
                                      value={record.note}
                                  />
                              </div>
                          ),
                  },
              ]
            : [
                  {
                      label: "Email",
                      children: record.email,
                  },
                  {
                      label: "Convênio",
                      children: record.convenios.map(({ id, name, color }) => {
                          return (
                              <Tag key={id} color={color}>
                                  {name}
                              </Tag>
                          );
                      }),
                  },
                  {
                      label: "Idade",
                      children: record.age,
                  },
                  {
                      label: "Endereço",
                      children: record.address,
                  },
                  {
                      label: "CPF",
                      children: record.cpf,
                  },
                  ...(record.responsible_cpf
                      ? [
                            {
                                label: "CPF do responsável",
                                children: record.responsible_cpf,
                            },
                        ]
                      : []),
                  {
                      label: "Telefone",
                      children: record.phone,
                  },
                  {
                      label: "Data de nascimento",
                      children: record.birthFormatted,
                  },
                  {
                      label: "Profissão",
                      children: record.occupation,
                  },
                  {
                      label: "Sexo",
                      children: record.gender,
                  },
                  {
                      label: "Notas",
                      children:
                          record.note === null ? (
                              "-"
                          ) : (
                              <div
                                  style={{ maxWidth: "300px" }}
                                  className="textEditorDescription"
                              >
                                  <TextEditor
                                      readOnly={true}
                                      value={record.note}
                                  />
                              </div>
                          ),
                  },
              ];

        return <Descriptions items={items} />;
    };

    const content = (
        <>
            {/* <Flex justify="end">
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                        marginBottom: "24px",
                    }}
                />
            </Flex> */}
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                expandable={{
                    expandedRowRender,
                }}
                scroll={{
                    x: 250,
                }}
                rowKey={(record) => record.id}
            />
            <Drawer
                title="Editar Paciente"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onClose}
                open={open}
                key="editPatient"
            >
                <FormComponent
                    name="editPatient"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    formItens={forms.patients.single}
                    onFileListChange={setFileList}
                    initialFileList={fileList}
                    selectSearchProps={{
                        data: {
                            convenios: dataUpdateConvenios,
                        },
                        endpoint: {
                            convenios:
                                endpoints.patients.indexUpdateConvenios.replace(
                                    "${}",
                                    id
                                ),
                        },
                    }}
                    openDrawer={open}
                    loading={loadingButtonEdit}
                    patientId={id}
                    buttonText="Salvar alterações"
                />
            </Drawer>

            <PatientHistoric
                patientId={id}
                isMobile={isMobile}
                isModalOpen={isModalHistoricOpen}
                setIsModalOpen={setIsModalHistoricOpen}
            />
            <PatientAnamnese
                patientId={id}
                account={account}
                isMobile={isMobile}
                isModalOpen={isModalAnamneseOpen}
                setIsModalOpen={setIsModalAnamneseOpen}
                handleDownloadDocumentSigned={handleDownloadDocumentSigned}
                loadingDocSignDownload={loadingDocSignDownload}
                setLoadingDocSignDownload={setLoadingDocSignDownload}
                handleGetPatient={handleGetPatient}
            />
            <PatientTreatment
                patientId={id}
                isMobile={isMobile}
                isModalOpen={isModalTreatmentsOpen}
                setIsModalOpen={setIsModalTreatmentsOpen}
                dataActiveTreatments={dataActiveTreatments}
                fetchDataActiveTreatments={fetchDataActiveTreatments}
                role={role}
                userId={userId}
                handleGetPatient={handleGetPatient}
            />
            <PatientBudget
                patientId={id}
                account={account}
                userName={userName}
                isMobile={isMobile}
                isModalOpen={isModalBudgetsOpen}
                setIsModalOpen={setIsModalBudgetsOpen}
                handleGetPatient={handleGetPatient}
                dataActiveTreatments={dataActiveTreatments}
                fetchDataActiveTreatments={fetchDataActiveTreatments}
            />
            <PatientEvolution
                patientId={id}
                account={account}
                isMobile={isMobile}
                isModalOpen={isModalEvolutionsOpen}
                setIsModalOpen={setIsModalEvolutionsOpen}
                handleDownloadDocumentSigned={handleDownloadDocumentSigned}
                loadingDocSignDownload={loadingDocSignDownload}
                setLoadingDocSignDownload={setLoadingDocSignDownload}
                handleGetPatient={handleGetPatient}
                role={role}
                userId={userId}
            />
            <PatientFiles
                patientId={id}
                //account={account}
                isMobile={isMobile}
                isModalOpen={isModalFilesOpen}
                setIsModalOpen={setIsModalFilesOpen}
                //handleDownloadDocumentSigned={handleDownloadDocumentSigned}
                //loadingDocSignDownload={loadingDocSignDownload}
                //setLoadingDocSignDownload={setLoadingDocSignDownload}
                //handleGetPatient={handleGetPatient}
                //role={role}
                //userId={userId}
            />
        </>
    );
    return (
        <Layout tabkey="2" content={content} setFetchDataPatients={fetchData} />
    );
};

export default Patients;
