//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import TextEditor from "../components/TextEditor";
import { SearchComponent } from "../components/SearchComponent";
import FormSchedule from "../components/FormSchedule";

//Icons
import { FaEdit, FaCalendarAlt, FaClock } from "react-icons/fa";

//Library components
import {
    Button,
    Table,
    Flex,
    Tag,
    message,
    Drawer,
    Form,
    Descriptions,
    Typography,
} from "antd";

//Settings
import dayjs from "dayjs";
import formattedDate from "../assets/utils/formattedDate";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints, forms, localeTable } = pattern;
const { Text } = Typography;

const Schedules = () => {
    const { isMobile, role } = useAuth();

    const { getColumnSearchProps } = SearchComponent();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState(null);

    const [dataPatients, setDataPatients] = useState([]);
    const [dataActiveDentists, setDataActiveDentists] = useState([]);
    const [dataUpdateCategories, setDataUpdateCategories] = useState([]);

    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.schedules.index);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataActiveDentists = async () => {
        try {
            const response = await axios.get(
                endpoints.users.indexActiveDentists
            );
            setDataActiveDentists(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchDataActiveDentists();
        fetchData();
    }, []);

    const fetchDataPatients = async () => {
        try {
            const response = await axios.get(endpoints.patients.index);
            setDataPatients(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataUpdateCategories = async (id) => {
        try {
            const response = await axios.get(
                endpoints.schedules.indexUpdateCategories.replace("${}", id)
            );
            setDataUpdateCategories(response.data);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const showDrawer = (id) => {
        const schedule = { ...data.find((schedule) => schedule.id === id) };

        schedule.date = dayjs(schedule.dh_start);
        schedule.timerange = [dayjs(schedule.dh_start), dayjs(schedule.dh_end)];

        form.setFieldsValue(schedule);
        fetchDataPatients();
        fetchDataUpdateCategories(id);
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onFinish = async (values) => {
        const formattedData = formattedDate(values);
        try {
            await axios.post(
                endpoints.schedules.update.replace("${}", id),
                formattedData,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchData();
            onClose();
            message.success("Agendamento atualizado com sucesso!");
        } catch (error) {
            if (error.response.data.available === false) {
                return message.error("Horário não disponível!");
            }

            message.error("Campo(s) inválido(s)!");
        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const columns = !isMobile
        ? [
              {
                  title: "Paciente",
                  dataIndex: "patient_name",
                  key: "patient_name",
                  ...getColumnSearchProps("patient_name"),
              },
              {
                  title: "Data e horário",
                  key: "date",
                  ...getColumnSearchProps("date"),
                  render: (_, { date, start_time, end_time }) => (
                      <>
                          <Flex gap="small" align="center">
                              <FaCalendarAlt />
                              {date}
                          </Flex>
                          <Flex gap="small" align="center">
                              <FaClock />
                              {start_time} - {end_time}
                          </Flex>
                      </>
                  ),
              },

              {
                  title: "Dentista",
                  dataIndex: "user_name",
                  key: "user_name",
                  ...getColumnSearchProps("user_name"),
              },
              {
                  title: "Antecipável",
                  dataIndex: "is_anticipated",
                  key: "is_anticipated",
                  render: (_, { is_anticipated }) => (
                      <Tag key={is_anticipated}>
                          {is_anticipated ? "Sim" : "Não"}
                      </Tag>
                  ),
                  filters: [
                      {
                          text: "Sim",
                          value: true,
                      },
                      {
                          text: "Não",
                          value: false,
                      },
                  ],
                  onFilter: (value, record) => record.is_anticipated === value,
              },
              {
                  title: "Categoria",
                  dataIndex: "categories",
                  key: "categories",
                  filters: data
                      .flatMap((record) =>
                          record.categories && record.categories_ids.length > 0
                              ? record.categories
                              : []
                      )
                      .map(({ name }) => ({
                          text: name,
                          value: name,
                      }))
                      .filter(
                          (v, i, a) =>
                              a.findIndex((t) => t.value === v.value) === i
                      ),
                  onFilter: (value, record) =>
                      record.categories_ids.length > 0
                          ? record.categories.some(
                                (convenio) => convenio.name === value
                            )
                          : false,
                  render: (_, { categories }) =>
                      Array.isArray(categories) ? (
                          <>
                              {categories.map(({ id, name, color }) => {
                                  return (
                                      <Tag key={id} color={color}>
                                          {name}
                                      </Tag>
                                  );
                              })}
                          </>
                      ) : (
                          categories
                      ),
              },
              {
                  title: "Status",
                  dataIndex: "status",
                  key: "status",
                  ...getColumnSearchProps("status_name"),
                  render: (_, { status_name, status_color }) => {
                      return (
                          <Tag color={status_color} key={status_name}>
                              <div style={{ fontWeight: "500" }}>
                                  {status_name.toUpperCase()}
                              </div>
                          </Tag>
                      );
                  },
              },
              {
                  title: "Notas",
                  dataIndex: "note",
                  key: "note",
                  ...getColumnSearchProps("note"),
                  render: (_, { note }) =>
                      note === null ? (
                          "-"
                      ) : (
                          <div
                              style={{ maxWidth: "300px" }}
                              className="textEditorDescription"
                          >
                              <TextEditor readOnly={true} value={note} />
                          </div>
                      ),
              },
              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Flex>
                          <Button
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                              }}
                              onClick={() => {
                                  showDrawer(id);
                                  setId(id);
                              }}
                          >
                              <FaEdit />
                          </Button>
                      </Flex>
                  ),
              },
          ]
        : [
              {
                  title: "Agendamento",
                  key: "date",
                  render: (
                      _,
                      {
                          patient_name,
                          date,
                          start_time,
                          end_time,
                          status,
                          status_color,
                      }
                  ) => (
                      <>
                          <Text
                              style={{ width: 100 }}
                              ellipsis={{
                                  tooltip: true,
                              }}
                          >
                              {patient_name}
                          </Text>
                          <Flex gap="small" align="center">
                              <FaCalendarAlt />
                              {date}
                          </Flex>
                          <Flex gap="small" align="center">
                              <FaClock />
                              {start_time} - {end_time}
                          </Flex>
                          <Tag color={status_color} key={status}>
                              {status.toUpperCase()}
                          </Tag>
                      </>
                  ),
              },

              {
                  title: "Ações",
                  dataIndex: "id",
                  key: "id",
                  render: (_, { id }) => (
                      <Flex>
                          <Button
                              style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: "10px",
                              }}
                              onClick={() => {
                                  showDrawer(id);
                                  setId(id);
                              }}
                          >
                              <FaEdit />
                          </Button>
                      </Flex>
                  ),
              },
          ];

    const expandedRowRender = (record) => {
        const items = !isMobile
            ? []
            : [
                  {
                      label: "Dentista",
                      children: record.user_name,
                  },
                  {
                      label: "Categoria",
                      children: Array.isArray(record.categories) ? (
                          <>
                              {record.categories.map(({ id, name, color }) => {
                                  return (
                                      <Tag key={id} color={color}>
                                          {name}
                                      </Tag>
                                  );
                              })}
                          </>
                      ) : (
                          record.categories
                      ),
                  },
                  {
                      label: "Notas",
                      children:
                          record.note === null ? (
                              "-"
                          ) : (
                              <div
                                  style={{ maxWidth: "300px" }}
                                  className="textEditorDescription"
                              >
                                  <TextEditor
                                      readOnly={true}
                                      value={record.note}
                                  />
                              </div>
                          ),
                  },
              ];

        return <Descriptions items={items} />;
    };

    const content = (
        <>
            {/* <Flex justify="end">
                <Search
                    placeholder="Pesquisar..."
                    onSearch={onSearch}
                    onChange={(e) => onSearch(e.target.value)}
                    style={{
                        width: !isMobile ? 200 : "100%",
                        marginBottom: "24px",
                    }}
                />
            </Flex> */}
            <Table
                columns={columns}
                dataSource={data}
                locale={localeTable}
                loading={loading}
                expandable={!isMobile ? false : { expandedRowRender }}
                rowKey={(record) => record.id}
            />
            <Drawer
                title="Editar Agendamento"
                placement="right"
                closable={!isMobile ? false : true}
                width={!isMobile ? 378 : "100%"}
                onClose={onClose}
                open={open}
                key="right"
            >
                <FormSchedule
                    name="editSchedule"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    formItens={
                        role !== "DENTIST"
                            ? forms.schedules.editWithDentist
                            : forms.schedules.editWithoutDentist
                    }
                    selectSearchProps={{
                        data: {
                            patients: dataPatients,
                            categories: dataUpdateCategories,
                            dentists: dataActiveDentists,
                        },
                        endpoint: {
                            patients: endpoints.patients.index,
                            categories:
                                endpoints.schedules.indexUpdateCategories.replace(
                                    "${}",
                                    id
                                ),
                            dentists: endpoints.users.indexActiveDentists,
                        },
                    }}
                    openDrawer={open}
                    buttonText="Salvar alterações"
                />
            </Drawer>
        </>
    );
    return (
        <Layout
            tabkey="3"
            content={content}
            setFetchDataSchedules={fetchData}
        />
    );
};

export default Schedules;
