//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const AuthProtect = ({ children, allowedRoles }) => {
    const { authChecked, isAuthenticated, role } = useAuth();
    const [authenticatedEffect, setAuthenticatedEffect] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!authChecked) {
            return;
        }
        if (!isAuthenticated) {
            setAuthenticatedEffect(false);
            navigate("/");
            return;
        }
        if (
            children.props.allowedRoles &&
            !children.props.allowedRoles.includes(role)
        ) {
            navigate("/calendar");
            return;
        }
        if (isAuthenticated) {
            setAuthenticatedEffect(true);
        }
    }, [isAuthenticated, role, allowedRoles, navigate, authChecked]);

    if (authenticatedEffect) {
        return children;
    }
};

export default AuthProtect;
