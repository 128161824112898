// src/components/PatientFiles.js

import React, { useState, useEffect, useRef } from "react";

// Icons
import { FaEye, FaTrashAlt, FaFileDownload } from "react-icons/fa"; // Remova se não estiver usando

// Library components
import {
    Modal,
    List,
    Button,
    Upload,
    message,
    Tree,
    Input,
    Form,
    Popconfirm,
    Spin,
    Empty,
    Image,
} from "antd";
import {
    EditOutlined,
    InboxOutlined,
    FolderAddOutlined,
    ArrowLeftOutlined,
    FileOutlined,
    FolderOutlined,
    PlusOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    FileExcelOutlined,
    FilePptOutlined,
    FileImageOutlined,
} from "@ant-design/icons";

// Api
import axios from "../../api/axios";
import pattern from "../../api/pattern";

// Variables
const { endpoints, localeTable } = pattern;
const { TreeNode } = Tree;
const { Dragger } = Upload;

const PatientFiles = (props) => {
    const [loadingFiles, setLoadingFiles] = useState(true);
    const [treeData, setTreeData] = useState([]);
    const [transformTree, setTransformTree] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] =
        useState(false);
    const [newFolderName, setNewFolderName] = useState("");
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editItem, setEditItem] = useState(null); // Pode ser uma pasta ou um arquivo
    const [newName, setNewName] = useState("");
    const [previewImageUrl, setPreviewImageUrl] = useState(null);
    const [isImagePreviewVisible, setIsImagePreviewVisible] = useState(false);
    const countRender = useRef(0);
    const [form] = Form.useForm();

    // Função para encontrar um nó na árvore
    const findNode = (nodes, key) => {
        for (let node of nodes) {
            if (node.key === key.toString()) {
                return node;
            }
            if (node.children) {
                const found = findNode(node.children, key);
                if (found) return found;
            }
        }
        return null;
    };

    // Função para customizar árvore
    const transformTreeData = (nodes) => {
        // Verifica se existe um nó raiz e remove-o
        if (nodes.length === 1 && nodes[0].key === "root") {
            return nodes[0].children
                .filter((node) => !node.isFile) // Exclui arquivos
                .map((node) => ({
                    key: node.key,
                    title: generateTitle(node),
                    children: node.children
                        ? transformTreeData(node.children)
                        : [],
                    isFile: node.isFile,
                    fileId: node.fileId,
                    fileKey: node.fileKey,
                }));
        }

        return nodes
            .filter((node) => !node.isFile) // Exclui arquivos
            .map((node) => ({
                key: node.key,
                title: generateTitle(node),
                children: node.children ? transformTreeData(node.children) : [],
                isFile: node.isFile,
                fileId: node.fileId,
                fileKey: node.fileKey,
            }));
    };

    const fetchDataPatientFiles = async (folderId = null) => {
        try {
            const response = await axios.get(
                endpoints.patients.files.replace("${}", props.patientId),
                {
                    params: {
                        folderId, // Envia o folderId para o backend
                    },
                }
            );

            setTreeData(response.data);
            setTransformTree(transformTreeData(response.data));
            setLoadingFiles(false);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
            message.error("Erro ao obter dados dos arquivos.");
        }
    };

    useEffect(() => {
        if (countRender.current < 1) {
            countRender.current += 1;
            return;
        }
        if (props.isModalOpen === false) {
            return;
        }

        fetchDataPatientFiles();
    }, [props.isModalOpen]);

    const downloadFile = async (fileId, fileName) => {
        try {
            const response = await axios.get(
                endpoints.patients.downloadFile.replace("${}", fileId),
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            const {
                data: { url },
            } = response;

            if (!url) {
                throw new Error("URL de download não fornecida.");
            }

            // Criando uma URL local para o Blob
            const responseBlob = await fetch(url);
            const blob = await responseBlob.blob();
            const localUrl = window.URL.createObjectURL(blob);

            // Criando e acionando o link para download
            const link = document.createElement("a");
            link.href = localUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            // Remover o link e liberar a URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(localUrl);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
            message.error("Erro ao baixar o arquivo.");
        }
    };

    // const handleTreeSelect = (keys, event) => {
    //     const { node } = event;
    //     if (node.isFile) {
    //         // Ação para arquivos, como download
    //         return;
    //     }
    //     setSelectedKeys(keys);
    //     // Remover o prefixo 'folder-' ao definir o ID
    //     const folderKey = keys[0].replace("folder-", "");
    //     setCurrentFolderId(folderKey === "root" ? null : parseInt(folderKey));
    // };

    const handleTreeSelect = (keys, event) => {
        const { node } = event;
        setSelectedKeys(keys);
        // Remover o prefixo 'folder-' ao definir o ID
        const folderKey = keys[0].replace("folder-", "");
        setCurrentFolderId(folderKey === "root" ? null : parseInt(folderKey));
    };

    const handleUpload = {
        multiple: true,
        showUploadList: false,
        customRequest: async ({ file, onSuccess, onError, onProgress }) => {
            const formData = new FormData();
            formData.append("file", file);
            if (currentFolderId) {
                formData.append("folderId", currentFolderId);
            }

            try {
                const response = await axios.post(
                    endpoints.patients.uploadFiles.replace(
                        "${}",
                        props.patientId
                    ),
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem(
                                "token"
                            )}`,
                        },
                        onUploadProgress: (event) => {
                            const percent = Math.round(
                                (event.loaded * 100) / event.total
                            );
                            onProgress({ percent });
                        },
                    }
                );

                onSuccess(response.data, file);
                message.success(`${file.name} arquivo enviado com sucesso.`);
                fetchDataPatientFiles(currentFolderId);
            } catch (error) {
                console.error("Erro ao fazer upload:", error);
                onError(error);
                message.error(`${file.name} falha no upload.`);
            }
        },
        beforeUpload: (file) => {
            const isAllowedType =
                file.type === "image/png" ||
                file.type === "image/jpeg" ||
                file.type === "application/pdf" ||
                file.type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                file.type ===
                    "application/vnd.openxmlformats-officedocument.presentationml.presentation";

            if (!isAllowedType) {
                message.error(
                    "Você só pode fazer upload de arquivos PNG, JPEG, PDF, DOCX ou PPTX!"
                );
                return Upload.LIST_IGNORE;
            }

            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                message.error("O arquivo deve ser menor que 5MB!");
                return Upload.LIST_IGNORE;
            }

            return true;
        },
    };

    const renderTreeNodes = (data) => {
        return data.map((item) => {
            if (item.children) {
                // É uma pasta
                return (
                    <TreeNode
                        title={item.title}
                        key={item.key} // Chave já está prefixada no backend
                        isFile={false}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            // É um arquivo
            return (
                <TreeNode
                    title={item.title}
                    key={item.key} // Chave já está prefixada no backend
                    isFile={item.isFile}
                />
            );
        });
    };

    const openCreateFolderModal = () => {
        setIsCreateFolderModalOpen(true);
    };

    const handleCreateFolder = async () => {
        if (!newFolderName.trim()) {
            message.error("O nome da pasta não pode ser vazio.");
            return;
        }

        try {
            await axios.post(
                endpoints.patients.createFolder.replace("${}", props.patientId),
                {
                    name: newFolderName.trim(),
                    parentId: currentFolderId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );
            message.success("Pasta criada com sucesso.");
            setIsCreateFolderModalOpen(false);
            setNewFolderName("");
            fetchDataPatientFiles(currentFolderId); // Atualizar a lista de pastas
        } catch (error) {
            console.error("Erro ao criar a pasta:", error);
            if (error.response && error.response.data) {
                message.error(
                    error.response.data.message || "Erro ao criar a pasta."
                );
            } else {
                message.error("Erro ao criar a pasta.");
            }
        }
    };

    const handleBack = () => {
        if (currentFolderId) {
            // Encontrar o parentId da pasta atual
            const findParentId = (nodes, key) => {
                let parentId = null;

                const traverse = (nodes, parent) => {
                    for (let node of nodes) {
                        if (node.key === key.toString()) {
                            parentId = parent;
                            return;
                        }
                        if (node.children) {
                            traverse(node.children, node.key);
                        }
                    }
                };

                traverse(nodes, null);
                return parentId;
            };

            const parentId = findParentId(
                treeData,
                `folder-${currentFolderId}`
            );

            setCurrentFolderId(
                parentId
                    ? parentId !== "root"
                        ? parseInt(parentId.replace("folder-", ""))
                        : null
                    : null
            );
            setSelectedKeys(parentId ? [parentId] : []);
        }
    };

    const handleDeleteFile = async (fileId, fileName) => {
        try {
            // Fazer a requisição DELETE para o backend
            await axios.delete(
                endpoints.patients.deleteFile.replace("${}", fileId),
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`,
                    },
                }
            );

            // Exibir mensagem de sucesso
            message.success(`Arquivo "${fileName}" deletado com sucesso.`);

            // Atualizar a lista de arquivos
            fetchDataPatientFiles(currentFolderId);
        } catch (error) {
            console.error("Erro ao deletar o arquivo:", error);
            if (error.response && error.response.data) {
                message.error(
                    error.response.data.message || "Erro ao deletar o arquivo."
                );
            } else {
                message.error("Erro ao deletar o arquivo.");
            }
        }
    };

    const handleDeleteFolder = async (folderId, folderName) => {
        try {
            // Confirmar a ação com o usuário
            const confirmDelete = window.confirm(
                `Tem certeza que deseja deletar a pasta "${folderName}"?`
            );
            if (!confirmDelete) return;
            await axios.delete(
                endpoints.patients.deleteFolder.replace("${}", folderId)
            );
            message.success(`Pasta "${folderName}" excluída com sucesso.`);
            fetchDataPatientFiles(currentFolderId); // Atualizar a lista de pastas
        } catch (error) {
            console.error("Erro ao excluir a pasta:", error);
            message.error("Erro ao excluir a pasta.");
        }
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split(".").pop().toLowerCase();
        switch (extension) {
            case "pdf":
                return (
                    <FilePdfOutlined
                        style={{ fontSize: "24px", color: "#d9534f" }}
                    />
                );
            case "docx":
                return (
                    <FileWordOutlined
                        style={{ fontSize: "24px", color: "#0275d8" }}
                    />
                );
            case "xlsx":
                return (
                    <FileExcelOutlined
                        style={{ fontSize: "24px", color: "#5cb85c" }}
                    />
                );
            case "pptx":
                return (
                    <FilePptOutlined
                        style={{ fontSize: "24px", color: "#f0ad4e" }}
                    />
                );
            case "png":
            case "jpeg":
            case "jpg":
                return (
                    <FileImageOutlined
                        style={{ fontSize: "24px", color: "#5bc0de" }}
                    />
                );
            default:
                return (
                    <FileOutlined
                        style={{ fontSize: "24px", color: "#1890ff" }}
                    />
                );
        }
    };

    // Função auxiliar para identificar se um arquivo é uma imagem
    const isImage = (filename) => {
        const ext = filename.split(".").pop().toLowerCase();
        return ["png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(ext);
    };

    const renderListItems = () => {
        // Encontrar a pasta atual para listar seus arquivos e pastas
        const currentNode = currentFolderId
            ? findNode(treeData, `folder-${currentFolderId}`)
            : findNode(treeData, "root");

        if (!currentNode) return null;

        return (
            <List
                itemLayout="horizontal"
                dataSource={currentNode.children || []}
                locale={localeTable}
                renderItem={(item) => {
                    if (item.isFile) {
                        return (
                            <List.Item
                                actions={[
                                    isImage(item.title) && (
                                        <Button
                                            type="link"
                                            icon={<FaEye />}
                                            onClick={() => handlePreview(item)}
                                        />
                                    ),
                                    <Button
                                        type="link"
                                        icon={<FaFileDownload />}
                                        onClick={() =>
                                            downloadFile(
                                                item.fileId,
                                                item.title
                                            )
                                        }
                                    />,
                                    <Button
                                        type="link"
                                        onClick={() => openEditModal(item)}
                                        icon={<EditOutlined />}
                                        style={{ padding: 0, marginRight: 8 }}
                                    />,
                                    <Popconfirm
                                        title="Deletar o arquivo"
                                        description="Você tem certeza? o arquivo não poderá ser recuperado."
                                        onConfirm={() =>
                                            handleDeleteFile(
                                                item.fileId,
                                                item.title
                                            )
                                        }
                                        okText="Sim"
                                        cancelText="Não"
                                    >
                                        <Button
                                            type="link"
                                            icon={<FaTrashAlt />}
                                            danger
                                        />
                                    </Popconfirm>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        item.isFile ? (
                                            getFileIcon(item.title)
                                        ) : (
                                            <FolderOutlined
                                                style={{
                                                    fontSize: "24px",
                                                    color: "#fadb14",
                                                }}
                                            />
                                        )
                                    }
                                    title={item.title}
                                    description={
                                        item.isFile
                                            ? `Tamanho: ${(
                                                  item.fileSize / 1024
                                              ).toFixed(2)} KB`
                                            : "Pasta"
                                    }
                                />
                            </List.Item>
                        );
                    } else {
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        type="link"
                                        icon={<FaEye />}
                                        onClick={() => {
                                            setCurrentFolderId(
                                                item.key.replace("folder-", "")
                                            );
                                            setSelectedKeys([item.key]);
                                        }}
                                    />,
                                    <Button
                                        type="link"
                                        onClick={() => openEditModal(item)}
                                        icon={<EditOutlined />}
                                        style={{ padding: 0, marginRight: 8 }}
                                    />,
                                    <Popconfirm
                                        title="Deletar a pasta"
                                        description={
                                            <div>
                                                Você tem certeza? A pasta e todo
                                                o seu contéudo serão
                                                <br />
                                                excluídos e não será possível
                                                recuperar a mesma.
                                            </div>
                                        }
                                        onConfirm={() =>
                                            handleDeleteFolder(
                                                item.key.replace("folder-", ""),
                                                item.title
                                            )
                                        }
                                        okText="Sim"
                                        cancelText="Não"
                                    >
                                        <Button
                                            type="link"
                                            icon={<FaTrashAlt />}
                                            danger
                                        />
                                        ,
                                    </Popconfirm>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <FolderOutlined
                                            style={{
                                                fontSize: "24px",
                                                color: "#fadb14",
                                            }}
                                        />
                                    }
                                    title={item.title}
                                    description="Pasta"
                                />
                            </List.Item>
                        );
                    }
                }}
            />
        );
    };

    const generateTitle = (node) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <span>
                    {node.isFile ? (
                        getFileIcon(node.title)
                    ) : (
                        <FolderOutlined
                            style={{
                                marginRight: 8,
                                fontSize: "18px",
                                color: "#fadb14",
                            }}
                        />
                    )}
                    {node.title}
                </span>
                {/* <div>
                    <Button
                        type="link"
                        icon={<EditOutlined />}
                        onClick={() => openEditModal(node)}
                        style={{ padding: 0, marginRight: 8 }}
                    />
                    {node.isFile ? (
                        <Popconfirm
                            title="Deletar o arquivo"
                            description="Você tem certeza? o arquivo não poderá ser recuperado."
                            onConfirm={() =>
                                handleDeleteFile(node.fileId, node.title)
                            }
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button
                                type="link"
                                icon={<FaTrashAlt />}
                                danger
                                style={{ padding: 0 }}
                            />
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Deletar a pasta"
                            description={
                                <div>
                                    Você tem certeza? A pasta e todo o seu
                                    contéudo serão
                                    <br />
                                    excluídos e não será possível recuperar a
                                    mesma.
                                </div>
                            }
                            onConfirm={() =>
                                handleDeleteFolder(node.key, node.title)
                            }
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button
                                type="link"
                                icon={<FaTrashAlt />}
                                danger
                                style={{ padding: 0 }}
                            />
                        </Popconfirm>
                    )}
                </div> */}
            </div>
        );
    };

    const openEditModal = (node) => {
        setEditItem(node);
        setNewName(node.title);
        form.setFieldsValue({ name: node.title });
        setIsEditModalOpen(true);
    };

    const handleEditOk = async () => {
        try {
            const values = await form.validateFields();
            const { name } = values;

            if (editItem.isFile) {
                // Renomear arquivo
                await axios.patch(
                    endpoints.patients.renameFile.replace(
                        "${}",
                        editItem.fileId
                    ),
                    { name }
                );
                message.success("Arquivo renomeado com sucesso.");
            } else {
                // Renomear pasta
                await axios.patch(
                    endpoints.patients.renameFolder.replace(
                        "${}",
                        editItem.key.replace("folder-", "")
                    ),
                    { name }
                );
                message.success("Pasta renomeada com sucesso.");
            }

            // Atualizar a árvore de arquivos
            fetchDataPatientFiles(currentFolderId);
            setIsEditModalOpen(false);
            setEditItem(null);
            setNewName("");
        } catch (error) {
            console.error("Erro ao renomear:", error);
            message.error("Erro ao renomear o item.");
        }
    };

    const handleEditCancel = () => {
        setIsEditModalOpen(false);
        setEditItem(null);
        setNewName("");
    };

    // Função para pré-visualizar arquivos (imagens)
    const handlePreview = async (file) => {
        try {
            // Extrair o fileId a partir da chave (key)
            // Supondo que a key tenha o formato 'file-{id}', conforme definido no backend
            const fileId = file.fileId;

            // Fazer uma requisição para obter a URL assinada
            const response = await axios.get(
                endpoints.patients.getImageUrl.replace("${}", fileId)
            );

            const { url } = response.data;

            setPreviewImageUrl(url);
            setIsImagePreviewVisible(true);
        } catch (error) {
            console.error("Erro ao obter URL de pré-visualização:", error);
            message.error("Erro ao pré-visualizar o arquivo.");
        }
    };

    return (
        <>
            <Modal
                open={props.isModalOpen}
                width={800}
                onCancel={() => props.setIsModalOpen(false)}
                centered={true}
                footer={null}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "600px",
                    }}
                >
                    <div style={{ display: "flex", flex: 1 }}>
                        <div
                            style={{
                                width: "30%",
                                borderRight: "1px solid #f0f0f0",
                                paddingRight: "10px",
                                overflowY: "auto",
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {currentFolderId && (
                                    <Button
                                        icon={<ArrowLeftOutlined />}
                                        onClick={handleBack}
                                    >
                                        Voltar
                                    </Button>
                                )}
                                <Button
                                    type="primary"
                                    icon={<FolderAddOutlined />}
                                    onClick={openCreateFolderModal}
                                    style={{
                                        marginLeft: currentFolderId
                                            ? "10px"
                                            : "0",
                                        width: "100%",
                                    }}
                                ></Button>
                            </div>
                            {/* <Tree
                                showLine
                                onSelect={handleTreeSelect}
                                selectedKeys={selectedKeys}
                                defaultExpandAll
                            >
                                {renderTreeNodes(treeData)}
                            </Tree> */}
                            {transformTree.length > 0 ? (
                                <Tree
                                    showLine
                                    onSelect={handleTreeSelect}
                                    selectedKeys={selectedKeys}
                                    treeData={transformTree}
                                    defaultExpandAll
                                />
                            ) : (
                                <Empty description="Nenhuma pasta encontrada." />
                            )}
                        </div>
                        <div
                            style={{
                                width: "70%",
                                paddingLeft: "10px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            {/* <Upload {...handleUpload}>
                                <Button icon={<UploadOutlined />}>
                                    Adicionar Arquivos
                                </Button>
                            </Upload> */}
                            <Dragger {...handleUpload}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Clique ou arraste arquivos para fazer
                                    upload.
                                </p>
                                <p className="ant-upload-hint">
                                    Suporte para upload único ou em lote.
                                    Arquivos permitidos: PNG, JPEG, PDF, DOCX,
                                    PPTX. Tamanho máximo: 5MB por arquivo.
                                </p>
                            </Dragger>
                            <div
                                style={{
                                    marginTop: "20px",
                                    overflowY: "auto",
                                    flex: 1,
                                }}
                            >
                                {loadingFiles ? <Spin /> : renderListItems()}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Modal para Criar Nova Pasta */}
                <Modal
                    title="Criar Nova Pasta"
                    open={isCreateFolderModalOpen}
                    onOk={handleCreateFolder}
                    onCancel={() => setIsCreateFolderModalOpen(false)}
                    okText="Criar"
                    cancelText="Cancelar"
                >
                    <Input
                        placeholder="Nome da pasta"
                        value={newFolderName}
                        onChange={(e) => setNewFolderName(e.target.value)}
                        onPressEnter={handleCreateFolder}
                    />
                </Modal>
                {/* Modal para Editar Nome */}
                <Modal
                    title={`Editar ${editItem?.isFile ? "Arquivo" : "Pasta"}`}
                    open={isEditModalOpen}
                    onOk={handleEditOk}
                    onCancel={handleEditCancel}
                    okText="Salvar"
                    cancelText="Cancelar"
                >
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label={`Novo nome para ${
                                editItem?.isFile ? "arquivo" : "pasta"
                            }`}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, insira um novo nome.",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Insira o novo nome"
                                value={newName}
                                onChange={(e) => setNewName(e.target.value)}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <Image
                    src={previewImageUrl}
                    preview={{
                        visible: isImagePreviewVisible,
                        onVisibleChange: (visible) => {
                            setIsImagePreviewVisible(visible);
                            if (!visible) {
                                setPreviewImageUrl(null);
                            }
                        },
                    }}
                    style={{ display: "none" }} // Oculto, pois será controlado programaticamente
                />
            </Modal>
        </>
    );
};

export default PatientFiles;
