// src/pages/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";

//Library components
import { Button, Result } from "antd";

const NotFound = () => {
    const navigate = useNavigate();

    const handleBackHome = () => {
        navigate("/calendar");
    };

    return (
        <Result
            status="404"
            title="404 - Página Não Encontrada"
            subTitle="Desculpe, a página que você está procurando não existe."
            extra={
                <Button type="primary" onClick={handleBackHome}>
                    Voltar para página inicial
                </Button>
            }
        />
    );
};

export default NotFound;
