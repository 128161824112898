//React
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";

//Components
import Layout from "../layout/Layout";
import TextEditor from "../components/TextEditor";

//Library components
import {
    Switch,
    Modal,
    Descriptions,
    Tag,
    message,
    Select,
    Tooltip,
    Image,
    Flex,
    Avatar,
} from "antd";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import locale from "@fullcalendar/core/locales/pt-br";

import { UserOutlined } from "@ant-design/icons";

//Settings
import dayjs from "dayjs";

//Api
import axios from "../api/axios";
import pattern from "../api/pattern";

//Variables
const { endpoints } = pattern;

const Calendar = () => {
    const { isMobile, account, role } = useAuth();
    const [dataSchedules, setDataSchedules] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [schedule, setSchedule] = useState({});
    const [isSwitchActive, setIsSwitchActive] = useState(false);

    const [patientPhotoUrl, setPatientPhotoUrl] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get(endpoints.schedules.index);

            const formattedDataSchedules = response.data.map((item) => ({
                id: item.id,
                title: item.patient_name,
                start: dayjs(item.dh_start).toDate(),
                end: dayjs(item.dh_end).toDate(),
                backgroundColor: item.status_color,
                borderColor: item.status_color,
                schedule_id: item.id,
                patientId: item.patient_id,
                patient_name: item.patient_name,
                patientPhotoKey: item.patientPhotoKey,
                patient_convenios: item.patient_convenios,
                user_name: item.user_name,
                status_color: item.status_color,
                status: item.status,
                is_anticipated: item.is_anticipated,
                categories: item.categories,
                note: item.note,
                formattedStartTime: dayjs(item.dh_start).format("HH:mm"),
                formattedEndTime: dayjs(item.dh_end).format("HH:mm"),
            }));

            setDataSchedules(formattedDataSchedules);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    const fetchDataOnlyMe = async () => {
        try {
            const response = await axios.get(endpoints.schedules.indexMe);

            const formattedDataSchedules = response.data.map((item) => ({
                id: item.id,
                title: item.patient_name,
                start: dayjs(item.dh_start).toDate(),
                end: dayjs(item.dh_end).toDate(),
                backgroundColor: item.status_color,
                borderColor: item.status_color,
                schedule_id: item.id,
                patientId: item.patient_id,
                patient_name: item.patient_name,
                patientPhotoKey: item.patientPhotoKey,
                patient_convenios: item.patient_convenios,
                user_name: item.user_name,
                status_color: item.status_color,
                status: item.status,
                is_anticipated: item.is_anticipated,
                categories: item.categories,
                note: item.note,
                formattedStartTime: dayjs(item.dh_start).format("HH:mm"),
                formattedEndTime: dayjs(item.dh_end).format("HH:mm"),
            }));

            setDataSchedules(formattedDataSchedules);
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const descriptionItems = schedule.patient_name
        ? [
              {
                  label: "Horário",
                  children: `${schedule.formattedStartTime} - ${schedule.formattedEndTime}`,
              },
              {
                  label: "Paciente",
                  children: schedule.patient_name,
              },

              {
                  label: "Dentista",
                  children: schedule.user_name,
              },
              {
                  label: "Categoria",
                  children: Array.isArray(schedule.categories) ? (
                      <>
                          {schedule.categories.map(({ id, name, color }) => {
                              return (
                                  <Tag key={id} color={color}>
                                      {name}
                                  </Tag>
                              );
                          })}
                      </>
                  ) : (
                      schedule.categories
                  ),
              },
              {
                  label: "Convênio",
                  children: schedule.patient_convenios.map(
                      ({ id, name, color }) => {
                          return (
                              <Tag key={id} color={color}>
                                  {name}
                              </Tag>
                          );
                      }
                  ),
              },
              {
                  label: "Antecipável",
                  children: (
                      <Tag key={schedule.is_anticipated}>
                          {schedule.is_anticipated ? "Sim" : "Não"}
                      </Tag>
                  ),
              },
              {
                  label: "Notas",
                  children:
                      schedule.note === null ? (
                          "-"
                      ) : (
                          <div
                              style={{ maxWidth: "300px" }}
                              className="textEditorDescription"
                          >
                              <TextEditor
                                  readOnly={true}
                                  value={schedule.note}
                              />
                          </div>
                      ),
              },
          ]
        : [];

    const handleCancel = () => {
        setIsModalOpen(false);
        setPatientPhotoUrl(null);
    };

    const onFinishUpdateSchedule = async (value) => {
        try {
            await axios.post(
                endpoints.schedules.updateStatus.replace(
                    "${}",
                    schedule.schedule_id
                ),
                { status: value },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchData();
            message.success("Agendamento atualizado com sucesso!");
        } catch (error) {
            message.error("Campo(s) inválido(s)!");
        }
    };

    const statusSelectOptions = [
        {
            value: "agendado",
            label: "Agendado",
        },
        {
            value: "confirmado",
            label: "Confirmado",
        },
        {
            value: "faltou",
            label: "Faltou",
        },

        {
            value: "desmarcado_clinica",
            label: "Desmarcado pela clínica",
        },

        {
            value: "desmarcado_paciente",
            label: "Desmarcado pelo paciente",
        },
    ];

    const getPatientPhoto = async (patientId) => {
        try {
            const result = await axios.get(
                endpoints.patients.getAvatarUrl.replace("${}", patientId)
            );
            return result.data.url;
        } catch (error) {
            console.error("Erro ao obter a foto do paciente:", error);
            throw error;
        }
    };

    const content = (
        <>
            {role === "ADMIN_DENTIST" ? (
                <Tooltip
                    placement="left"
                    title={
                        !isSwitchActive
                            ? "Meus agendamentos"
                            : "Todos os agendamentos"
                    }
                    className="testeabc"
                >
                    <Switch
                        onChange={(value) => {
                            value ? fetchDataOnlyMe() : fetchData();
                            setIsSwitchActive(!isSwitchActive);
                        }}
                    />
                </Tooltip>
            ) : (
                ""
            )}
            <FullCalendar
                headerToolbar={{
                    start: !isMobile
                        ? "dayGridMonth,timeGridWeek"
                        : "dayGridDay,listWeek",
                    center: "title",
                    end: "today prev,next",
                }}
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
                initialView={!isMobile ? "dayGridMonth" : "dayGridDay"}
                // businessHours={{
                //     daysOfWeek: [0, 1, 2, 3, 4, 5, 6],

                //     startTime: account?.opening_start,
                //     endTime: account?.opening_end,
                // }}
                locale={locale}
                events={dataSchedules}
                nowIndicator={true}
                allDaySlot={false}
                slotMinTime={account?.opening_start}
                slotMaxTime={account?.opening_end}
                eventClick={async (info) => {
                    const selectedSchedule = info.event._def.extendedProps;
                    setSchedule(selectedSchedule);

                    if (selectedSchedule.patientPhotoKey) {
                        try {
                            const url = await getPatientPhoto(
                                selectedSchedule.patientId
                            );
                            setPatientPhotoUrl(url);
                        } catch (error) {
                            console.error(
                                "Erro ao obter a URL da foto:",
                                error
                            );
                            setPatientPhotoUrl(null);
                        }
                    } else {
                        setPatientPhotoUrl(null);
                    }

                    setIsModalOpen(true);
                }}
                dayMaxEventRows={3}
                moreLinkClick="week"
                eventTimeFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    meridiem: false,
                }}
                displayEventEnd={{
                    month: false,
                    basicWeek: true,
                    default: true,
                }}
                height={isMobile ? 700 : "100%"}
            />

            <Modal
                title="Agendamento"
                open={isModalOpen}
                onCancel={handleCancel}
                centered={true}
                footer={(_, {}) => (
                    <Select
                        style={{ width: "200px", textAlign: "left" }}
                        value={schedule.status}
                        options={statusSelectOptions}
                        onSelect={(value) => {
                            setSchedule((originalSchedule) => ({
                                ...originalSchedule,
                                status: value,
                            }));
                            onFinishUpdateSchedule(value);
                        }}
                    />
                )}
            >
                <Flex>
                    <Descriptions
                        items={descriptionItems}
                        size="small"
                        column={1}
                    />
                    <div>
                        {schedule.patientPhotoKey ? (
                            patientPhotoUrl ? (
                                <Image
                                    preview={{
                                        mask: "Pré-visualizar",
                                    }}
                                    width={150}
                                    src={patientPhotoUrl}
                                />
                            ) : (
                                <Avatar
                                    shape="square"
                                    size={150}
                                    icon={<UserOutlined />}
                                />
                            )
                        ) : (
                            <Avatar
                                shape="square"
                                size={150}
                                icon={<UserOutlined />}
                            />
                        )}
                    </div>
                </Flex>
            </Modal>
        </>
    );
    return (
        <Layout
            tabkey="1"
            content={content}
            setFetchDataSchedules={fetchData}
        />
    );
};

export default Calendar;
