import React, { useState, useEffect } from "react";
import { Select, InputNumber, Flex } from "antd";

const SelectBeforeInput = ({ value = {}, onChange, treatmentsSumValue }) => {
    const [typeDiscount, setTypeDiscount] = useState(value.type || "%");
    const [inputValue, setInputValue] = useState(value.value || 0);

    useEffect(() => {
        setTypeDiscount(value.type || "%");
        setInputValue(value.value || 0);
    }, [value.type, value.value]);

    const onTypeChange = (val) => {
        setTypeDiscount(val);
        setInputValue(0);
        onChange?.({ type: val, value: 0 });
    };

    const onValueChange = (val) => {
        setInputValue(val);
        onChange?.({ type: typeDiscount, value: val });
    };

    return (
        <Flex gap="small">
            <Select
                value={typeDiscount}
                onChange={onTypeChange}
                options={[
                    { value: "%", label: "%" },
                    { value: "R$", label: "R$" },
                ]}
            />
            <InputNumber
                style={{ width: "100%" }}
                onChange={onValueChange}
                value={inputValue}
                min={0}
                max={typeDiscount === "%" ? 100 : treatmentsSumValue}
            />
        </Flex>
    );
};

export default React.memo(SelectBeforeInput);
